import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

import type { NavItem } from '../../../config/navigation';
import { isArrayOf, isObjectWithKeys } from '../../../services/guards';
import useOnClickOutside from '../../../services/hooks/useOnClickOutside';

import Avatar from '../Avatar';
import Body from '../Body';
import Inline404 from '../Inline404';
import NavButton from '../NavButton';
import TextGroup from '../TextGroup';

const StyledAccordion = styled(Accordion)`
  &&& {
    margin: 0;
    padding: 0;

    &.MuiAccordion-root::before {
      display: none;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &&& {
    padding: ${({ theme }) => theme.scTheme.space[600]};

    .MuiAccordionSummary-content {
      margin: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.scTheme.palette.greyscale[10]};
    }

    &:active {
      background-color: ${({ theme }) => theme.scTheme.palette.greyscale[20]};
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &&& {
    display: flex;
    flex-direction: column;

    &.MuiAccordionDetails-root {
      padding: ${({ theme }) => theme.scTheme.space[500]} ${({ theme }) => theme.scTheme.space[300]};
      padding-top: 0;
    }
  }
`;

const isNavItem = (arg: unknown): arg is NavItem => isObjectWithKeys<NavItem>(arg, ['id', 'url', 'title']);

export type Props = Readonly<{
  navigation: ReactNode[] | readonly NavItem[];
  caption?: string;
}>;

const ProfileLink = ({ navigation, caption }: Props): ReactElement => {
  const auth = useAuth();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const { ref } = useOnClickOutside<HTMLDivElement>({ handler: () => setExpanded(false) });

  const {
    id, username, firstName, lastName, attributes,
  } = auth.getUserProfile();

  if (isNil(id) || isNil(username)) {
    return <Inline404 />;
  }

  const user = {
    id,
    firstName: firstName ?? '',
    lastName: lastName ?? username,
    avatar: attributes?.avatar?.[0],
  };

  return (
    <StyledAccordion expanded={expanded} ref={ref}>
      <StyledAccordionSummary
        aria-label={t(expanded ? 'Close profile options menu' : 'Open profile options menu')}
        onClick={() => setExpanded(!expanded)}
      >
        <Box mr={500}>
          <Avatar user={user} />
        </Box>
        <TextGroup hideOverflow>
          <Body size={300}>
            {firstName}
            {' '}
            {lastName}
          </Body>
          <Body size={200} variant="secondary">
            {caption ?? username}
          </Body>
        </TextGroup>
      </StyledAccordionSummary>
      <StyledAccordionDetails aria-hidden={!expanded}>
        <>
          {isArrayOf(navigation, isNavItem)
            ? navigation.map((item) => (
              <Box key={item.id}>
                <NavButton item={item} />
              </Box>
            )) : navigation}
        </>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ProfileLink;
