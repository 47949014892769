import first from 'lodash/first';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import type { DateTimeUnit } from 'luxon';
import { DateTime, Interval as LuxonInterval } from 'luxon';
import type { Writable } from 'type-fest';

import type { CalendarDay } from '../schema/user';

import type { DateFormat } from './format';
import { isLiteral, isNotNil } from './guards';
import type { LocaleInfo } from './i18n';
import type { NonMutable, Nullable } from './object';

type Interval = [Date | undefined, Date | undefined];

/**
 * checks if dateA and dateB are in the same time interval (left inclusive)
 * @param dates dates from which construct intervals
 * @param dateA, @param dateB dates to check
 */

export const areInTheSameInterval = (dates: Date[], dateA: Date, dateB: Date): boolean => {
  const sortedDates = sortBy(dates);

  const intervals: Interval[] = [
    ...sortedDates.map((date, index): Interval => [sortedDates[index - 1], date]),
    [last(sortedDates), undefined],
  ];

  return intervals.some((interval) => {
    const start = first(interval);
    const end = last(interval);

    if (!isNil(start) && !isNil(end)) {
      return dateA >= start && dateA < end
        && dateB >= start && dateB < end;
    }

    if (isNil(start) && !isNil(end)) {
      return dateA < end && dateB < end;
    }

    if (!isNil(start) && isNil(end)) {
      return dateA >= start && dateB >= start;
    }

    return true;
  });
};

export function getDateTime(date: Date | DateTime, format?: DateFormat): DateTime;
export function getDateTime(date: Nullable<Date | DateTime | string>, format?: DateFormat): Nullable<DateTime>;
export function getDateTime(date: Nullable<Date | DateTime | string>, format?: DateFormat): Nullable<DateTime> {
  if (isNil(date)) {
    return null;
  }

  let dateTime: DateTime | null = null;

  if (isString(date)) {
    dateTime = isNil(format) ? DateTime.fromISO(date) : DateTime.fromFormat(date, format);
  } else if (isDate(date)) {
    dateTime = DateTime.fromJSDate(date);
  } else {
    dateTime = date;
  }

  return dateTime.isValid ? dateTime.setLocale('en-GB') : null;
}

export const getToday = (): DateTime => getDateTime(getToday());

export const getEndOfTheWeek = (date: Nullable<Date | DateTime | string>, localeInfo: LocaleInfo): Nullable<Date> => {
  const dateTime = getDateTime(date);

  if (isNil(dateTime)) {
    return null;
  }

  const endOfWeek = dateTime.set({ weekday: localeInfo.firstDayOfWeek }).minus({ days: 1 });
  return (endOfWeek < dateTime ? endOfWeek.plus({ weeks: 1 }) : endOfWeek).toJSDate();
};

const getLastWorkDay = (localeInfo: LocaleInfo) => {
  const workingDaysInWeek = Array.from({ length: 7 }, (_, i) => (localeInfo.firstDayOfWeek + i) % 7)
    .filter((day) => !localeInfo.daysFreeOfWork.includes(day));

  return last(workingDaysInWeek);
};

export const getEndOfTheWorkWeek = (date: Nullable<Date | string>, localeInfo: LocaleInfo): Nullable<Date> => {
  const dateTime = getDateTime(date);

  if (isNil(dateTime)) {
    return null;
  }

  const lastWorkDay = getLastWorkDay(localeInfo);

  if (isNil(lastWorkDay)) {
    return null;
  }

  const endOfWorkWeek = dateTime.set({ weekday: lastWorkDay });
  return (endOfWorkWeek < dateTime ? endOfWorkWeek.plus({ weeks: 1 }) : endOfWorkWeek).toJSDate();
};

export const getEndOfTheWorkMonth = (
  date: Nullable<Date | string>, localeInfo: LocaleInfo,
): Nullable<Date> => {
  const dateTime = getDateTime(date)?.endOf('month');

  if (isNil(dateTime)) {
    return null;
  }

  if (!localeInfo.daysFreeOfWork.includes(dateTime.toJSDate().getDay())) {
    return dateTime.toJSDate();
  }

  const lastWorkDay = getLastWorkDay(localeInfo);

  if (isNil(lastWorkDay)) {
    return null;
  }

  return dateTime.set({ weekday: lastWorkDay }).toJSDate();
};

export const getWeekendsInMonth = (
  date: Date | DateTime, localeInfo: LocaleInfo,
): DateTime[] => {
  const startOfMonth = getDateTime(date).startOf('month');

  if (isNil(startOfMonth)) {
    return [];
  }

  return Array.from({ length: 7 }, (_, i) => {
    const week = startOfMonth.plus({ weeks: i });
    return localeInfo.daysFreeOfWork.map((day) => week.set({ weekday: day }));
  }).flat().filter((weekend) => weekend.hasSame(startOfMonth, 'month'));
};

type JsInterval = Readonly<{
  from: Date;
  to: Nullable<Date>;
}>;

export function getInterval(interval: JsInterval): LuxonInterval;
export function getInterval(dateA: Date | DateTime, dateB: Nullable<Date | DateTime>): LuxonInterval;
export function getInterval(
  dateOrInterval: Date | DateTime | JsInterval, otherDate?: Nullable<Date | DateTime>,
): LuxonInterval {
  const dateA = isLiteral(dateOrInterval) ? dateOrInterval.from : dateOrInterval;
  const dateB = isLiteral(dateOrInterval) ? dateOrInterval.to ?? dateA : otherDate ?? dateA;

  const start = getDateTime(dateA <= dateB ? dateA : dateB).startOf('day');
  const end = getDateTime(dateA <= dateB ? dateB : dateA).endOf('day');

  return LuxonInterval.fromDateTimes(start, end);
}

export const intervalToDateTimes = (interval: Nullable<LuxonInterval>): DateTime[] => {
  if (isNil(interval)) {
    return [];
  }

  const { start, end } = interval;

  if (isNil(start) || isNil(end)) {
    return [];
  }

  const days: DateTime[] = [];
  let offset = 0;

  while (last(days)?.hasSame(end, 'day') !== true) {
    days.push(start.plus({ day: offset }));
    offset += 1;
  }

  return days;
};

export const isWorkingDay = (
  dateTime: DateTime,
  holidays: NonMutable<CalendarDay[]>,
  localeInfo: LocaleInfo,
): boolean => {
  const weekend = localeInfo.daysFreeOfWork.includes(dateTime.toJSDate().getDay());
  const holiday = holidays
    .some(({ date }) => DateTime.fromJSDate(date).hasSame(dateTime, 'day'));

  return !weekend && !holiday;
};

// does not count holidays as weekends
export const isWeekend = (localeInfo: LocaleInfo, day: Nullable<Date | DateTime | number>): boolean => {
  if (isNil(day)) {
    return false;
  }

  const dayNumber = isNumber(day)
    ? day
    // converted back to js date since DateTime indexes them differently
    : getDateTime(day).toJSDate().getDay();

  return localeInfo.daysFreeOfWork.includes(dayNumber);
};

export const workingDaysInInterval = (
  startDate: Date,
  endDate: Date,
  holidays: NonMutable<CalendarDay[]>,
  localeInfo: LocaleInfo,
): DateTime[] => getInterval(startDate, endDate).splitBy({ days: 1 })
  .map((day) => day.start)
  .filter(isNotNil)
  .filter((day) => isWorkingDay(day, holidays, localeInfo));

export const compareWeeks = (
  date: Date,
  otherDate: Date,
  localeInfo: LocaleInfo,
): number => {
  const dateTime = DateTime.fromJSDate(date);
  const currentDateTime = DateTime.fromJSDate(otherDate);

  const isSameWeek = dateTime.set({ weekday: localeInfo.firstDayOfWeek })
    .hasSame(currentDateTime.set({ weekday: localeInfo.firstDayOfWeek }), 'day');

  if (isSameWeek) {
    return 0;
  }

  if (date > otherDate) {
    return 1;
  }

  return -1;
};

export const toISOMonth = (
  base: Date | DateTime,
): string => {
  const date = getDateTime(base).toJSDate();
  const month = date.getMonth() + 1;

  return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}`;
};

export const toISODate = (
  base: Date | DateTime,
): string => {
  const date = getDateTime(base).toJSDate();
  const day = date.getDate();

  return `${toISOMonth(base)}-${day < 10 ? '0' : ''}${day}`;
};

export const diffInSecondsFromMidnight = (otherDate: Date, relativeTo = otherDate): number => {
  const relativeToCopy = getDateTime(relativeTo).toJSDate();
  const otherDateCopy = getDateTime(otherDate).toJSDate();

  return otherDateCopy.setMilliseconds(0) - relativeToCopy.setHours(0, 0, 0, 0);
};

export const diffInDays = (
  date: Date | DateTime, relativeTo: Date | DateTime,
): number => (relativeTo > date ? -1 : 1) * (getInterval(
  getDateTime(relativeTo),
  getDateTime(date),
).count('days') - 1);

export function compareTimeUnits(
  firstDate: Date | DateTime, secondDate: Date | DateTime, type: DateTimeUnit
): number;
export function compareTimeUnits(
  firstDate: Nullable<Date | DateTime>, secondDate: Nullable<Date | DateTime>, type: DateTimeUnit,
): Nullable<number>;
export function compareTimeUnits(
  firstDate: Nullable<Date | DateTime>, secondDate: Nullable<Date | DateTime>, type: DateTimeUnit,
): Nullable<number> {
  const effectiveDateTime = getDateTime(firstDate)?.startOf(type);
  const otherDateTime = getDateTime(secondDate)?.startOf(type);

  if (isNil(effectiveDateTime) || isNil(otherDateTime)) {
    return isNil(firstDate) && isNil(secondDate) ? 0 : null;
  }

  if (effectiveDateTime.hasSame(otherDateTime, type)) {
    return 0;
  }

  if (effectiveDateTime.endOf(type) > otherDateTime.endOf(type)) {
    return 1;
  }

  return -1;
}

export const getNthDayFrom = (date: Nullable<Date>, amount = 1): Nullable<Date> => {
  if (isNil(date)) {
    return null;
  }

  return DateTime.fromJSDate(date).plus({ day: amount }).toJSDate();
};

const getWorkingDayFrom = (
  localeInfo: LocaleInfo,
  holidays: NonMutable<CalendarDay[]>,
  dt: DateTime,
  offset: number,
  step: number,
): DateTime => {
  if (!isWorkingDay(dt, holidays, localeInfo)) {
    return getWorkingDayFrom(localeInfo, holidays, dt.plus({ day: step }), offset, step);
  }

  if (offset === 0) {
    return dt;
  }

  return getWorkingDayFrom(localeInfo, holidays, dt.plus({ day: step }), offset - 1, step);
};

export const getWorkingDayBefore = (
  localeInfo: LocaleInfo,
  holidays: NonMutable<CalendarDay[]>,
  dt: DateTime,
  offset: number,
): DateTime => getWorkingDayFrom(localeInfo, holidays, dt, offset, -1);

export const getWorkingDayAfter = (
  localeInfo: LocaleInfo,
  holidays: NonMutable<CalendarDay[]>,
  dt: DateTime,
  offset: number,
): DateTime => getWorkingDayFrom(localeInfo, holidays, dt, offset, 1);

export type LiteralRange = Readonly<{
  from: DateTime;
  to: DateTime;
}>;

export const mergeSequentialDates = (dates: (Date | DateTime)[]): LiteralRange[] => dates
  .map((date) => getDateTime(date))
  .reduce<Writable<LiteralRange>[]>((acc, date) => {
  const lastRange = last(acc);

  const newRange = { from: date, to: date };

  if (isNil(lastRange)) {
    acc.push(newRange);
  } else if (lastRange.to.plus({ days: 1 }).hasSame(date, 'day')) {
    lastRange.to = date;
  } else {
    acc.push(newRange);
  }

  return acc;
}, []);

export const intervalContainsInclusive = (
  interval: LuxonInterval | { from: Date | DateTime; to?: Nullable<Date | DateTime> },
  date: Date | DateTime,
): boolean => {
  if ('from' in interval) {
    return compareTimeUnits(interval.from, date, 'day') <= 0 && (
      isNil(interval.to) || compareTimeUnits(interval.to, date, 'day') >= 0
    );
  }

  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);

  return !isNil(interval.end)
    && !isNil(interval.start)
    && (interval.contains(dateTime) || interval.end.equals(dateTime) || interval.start.equals(dateTime));
};

export const getTimeZoneAwareDate = (iso: string): Date => {
  const parseDate = (dateISO: string) => dateISO.split('-').map((part) => Number(part));
  const parseTime = (timeISO: string) => timeISO.split(':').map((part) => Number(part));

  // date
  if (/^\d{4}-\d{2}-\d{2}$/.test(iso)) {
    const [year, month, day] = parseDate(iso);
    return DateTime.local().set({ year, month, day }).toJSDate();
  }

  // time
  if (/^\d{2}:\d{2}:\d{2}$/.test(iso)) {
    const [hour, minute, second] = parseTime(iso);
    return DateTime.local().set({ hour, minute, second }).toJSDate();
  }

  // datetime
  const [date, time] = iso.split('T');
  const [year, month, day] = parseDate(date);
  const [hour, minute, second] = parseTime(time);

  return DateTime.local().set({
    year, month, day, hour, minute, second,
  }).toJSDate();
};

export const getOrderedDaysInWeek = (localeInfo: LocaleInfo): number[] => Array.from({ length: 7 },
  (_, i) => (localeInfo.firstDayOfWeek + i) % 7);

export default {};
